// helpers to hide elements in various ways
// ======================================================================

// completely hidden
.hidden,
%hidden {
  display: none;
  visibility: hidden;
}

// hidden, but focusable and screenreader-compatible
.visuallyhidden,
%visuallyhidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);

  &.focusable {
    &:active,
    &:focus {
      position: static;
      width: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      clip: auto;
    }
  }
}

// hide, but maintain layout
.invisible,
%invisible {
  visibility: hidden;
}
