// attention mixin for hover-, focus- and active-states
// ======================================================================

@mixin attention {
  &:hover,
  &:active,
  &:focus {
    @content;
  }
}
