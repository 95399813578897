// box ratio / add custom boxes with a fixed (responsive) ratio
// ======================================================================

// boxes with a fixed ratio use like:
// <div class="box  box--16-9">
//   <div class="box__content"></div>
// </div>

.box {
  position: relative;
  display: block;
  overflow: hidden;

  &::before {
    display: block;
    content: '';
    padding-top: 100%; // initial ratio of 1:1
  }
}

.box__content {
  @include center(cover);
}

// and now - the various box sizes
.box--2-1 {
  @include box(2, 1);
}

.box--1-2 {
  @include box(1, 2);
}

.box--4-3 {
  @include box(4, 3);
}

.box--16-9 {
  @include box(16, 9);
}
