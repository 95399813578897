// some basic keyframe animations
// ======================================================================

// rotates an element once
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes animation-1 {
  0% {
    transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, -500, 0, 1);
  }

  2.38095% {
    transform: matrix3d(0.51288, 0, 0, 0, 0, 0.51288, 0, 0, 0, 0, 1, 0, 0, -338.45863, 0, 1);
  }

  4.76191% {
    transform: matrix3d(0.7853, 0, 0, 0, 0, 0.7853, 0, 0, 0, 0, 1, 0, 0, -207.30949, 0, 1);
  }

  7.14286% {
    transform: matrix3d(0.91741, 0, 0, 0, 0, 0.91741, 0, 0, 0, 0, 1, 0, 0, -110.81914, 0, 1);
  }

  9.52381% {
    transform: matrix3d(0.97533, 0, 0, 0, 0, 0.97533, 0, 0, 0, 0, 1, 0, 0, -45.99977, 0, 1);
  }

  11.9048% {
    transform: matrix3d(0.99743, 0, 0, 0, 0, 0.99743, 0, 0, 0, 0, 1, 0, 0, -6.66537, 0, 1);
  }

  14.2857% {
    transform: matrix3d(1.0039, 0, 0, 0, 0, 1.0039, 0, 0, 0, 0, 1, 0, 0, 14.05956, 0, 1);
  }

  16.6667% {
    transform: matrix3d(1.00447, 0, 0, 0, 0, 1.00447, 0, 0, 0, 0, 1, 0, 0, 22.3791, 0, 1);
  }

  19.0476% {
    transform: matrix3d(1.00334, 0, 0, 0, 0, 1.00334, 0, 0, 0, 0, 1, 0, 0, 23.2423, 0, 1);
  }

  21.4286% {
    transform: matrix3d(1.00209, 0, 0, 0, 0, 1.00209, 0, 0, 0, 0, 1, 0, 0, 20.23606, 0, 1);
  }

  23.8095% {
    transform: matrix3d(1.00117, 0, 0, 0, 0, 1.00117, 0, 0, 0, 0, 1, 0, 0, 15.73295, 0, 1);
  }

  26.1905% {
    transform: matrix3d(1.0006, 0, 0, 0, 0, 1.0006, 0, 0, 0, 0, 1, 0, 0, 11.14505, 0, 1);
  }

  28.5714% {
    transform: matrix3d(1.00028, 0, 0, 0, 0, 1.00028, 0, 0, 0, 0, 1, 0, 0, 7.19352, 0, 1);
  }

  30.9524% {
    transform: matrix3d(1.00012, 0, 0, 0, 0, 1.00012, 0, 0, 0, 0, 1, 0, 0, 4.14609, 0, 1);
  }

  33.3333% {
    transform: matrix3d(1.00004, 0, 0, 0, 0, 1.00004, 0, 0, 0, 0, 1, 0, 0, 2.0033, 0, 1);
  }

  35.7143% {
    transform: matrix3d(1.00001, 0, 0, 0, 0, 1.00001, 0, 0, 0, 0, 1, 0, 0, 0.63156, 0, 1);
  }

  38.0952% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.15025, 0, 1);
  }

  40.4762% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.52034, 0, 1);
  }

  42.8571% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.62906, 0, 1);
  }

  45.2381% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.59053, 0, 1);
  }

  47.619% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.4838, 0, 1);
  }

  50% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.35866, 0, 1);
  }

  52.381% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.24278, 0, 1);
  }

  54.7619% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.14871, 0, 1);
  }

  57.1429% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.07949, 0, 1);
  }

  59.5238% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.033, 0, 1);
  }

  61.9048% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.00478, 0, 1);
  }

  64.2857% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.01009, 0, 1);
  }

  66.6667% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.01605, 0, 1);
  }

  69.0476% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.01667, 0, 1);
  }

  71.4286% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.01452, 0, 1);
  }

  73.8095% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.01129, 0, 1);
  }

  76.1905% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.00799, 0, 1);
  }

  78.5714% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.00516, 0, 1);
  }

  80.9524% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.00297, 0, 1);
  }

  83.3333% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.00144, 0, 1);
  }

  85.7143% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.00045, 0, 1);
  }

  88.0952% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.00011, 0, 1);
  }

  90.4762% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.00037, 0, 1);
  }

  92.8571% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.00045, 0, 1);
  }

  95.2381% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.00042, 0, 1);
  }

  97.619% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.00035, 0, 1);
  }

  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

}

// it is wise to try to animate only transforms and opacity for animation
// because those are cheap for the browser and result in smoother animations

// default animation, 400ms ^= jquery default animation time :-)
.animate,
%animate {
  // transition: opacity 0.4s ease-in, transform 0.4s ease-in;
  transition: all .4s;
}
