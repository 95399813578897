// custom @font-face includes are defined here - use mixin
// ======================================================================

// @include font-face('Font', '../fonts/font', 700, normal);

@include font-face('Fira Mono', '../fonts/firamono-regular', normal, normal);
@include font-face('Fira Sans', '../fonts/firasans-thin', 100, normal);
@include font-face('Fira Sans', '../fonts/firasans-light', 300, normal);
@include font-face('Fira Sans', '../fonts/firasans-book', normal, normal);
@include font-face('Fira Sans', '../fonts/firasans-medium', bold, normal);
