// tables and cell/row styling
// ======================================================================

table {
  width: 100%;
}

td {
  vertical-align: top;
}

th {
  text-align: left;
}
