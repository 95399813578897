// buttons
// ======================================================================

$border-width: 2px;

%button {
  @include spacing-inner(l 1, r 1, b 0, t 0);
  @include relative;
  display: inline-block;
  line-height: $base-double;
  font-weight: 700;
  transition: color 0.3s;
  background: $color-inverted;
  color: $color-default;
  border: $border-width solid $color-border;
  outline: 0;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  letter-spacing: 0.2em;
  min-width: $base-quadruple * 2.5;
  max-width: $base-quadruple * 4;
  height: $base-double;
}

.button {
  @extend %button;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    border-style: solid;
    position: absolute;
    z-index: 5;
    box-sizing: content-box;
    transition: width .3s, height .3s;
    border-color: $color-main;
  }

  &:before {
    width: 0;
    height: 100%;
    border-width: $border-width 0;
    top: - $border-width;
    left: 0;
    transition-delay: 0.05s;
  }

  &:after {
    width: 100%;
    height: 0;
    border-width: 0 $border-width;
    top: 0;
    left: - $border-width;
  }

  &:hover {
    color: $color-main;

    &:before {
      width: 100%;
    }

    &:after {
      height: 100%;
    }
  }

  // NAV
  &--toggle-nav {
    @extend %animate;
    @include fixed(t 12px, r 10px);
    @include z-index(sky);

    // we don't need this button on large displays
    @include mediaquery(l) {
      display: none;
    }

    &--active {
      transform: rotate(45deg);
    }
  }
}

.feedback-success {
  @extend %button;
  @include center;
  background: $color-main;
  border-color: $color-main;
  color: $color-inverted;
  display: block;
}
