// global footer styles
// ======================================================================

footer {
  background: $color-default;
  color: $color-inverted;
  text-align: center;

  ul {
    @include spacing(t 2.5, b 1.5);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;

    @include mediaquery(l) {
      @include spacing(b 3.5);
    }
  }

  li {
    @include spacing-inner(l .5, r .5, b 1);
  }

  h3 {
    font-weight: 700;
    text-transform: uppercase;
    color: $color-inverted;
  }

  p {
    color: $color-inverted;
  }
}

.copyright {
  @include font-size(h6);
  font-weight: 300;
  color: darken($color-inverted, 20%);
}
