// a mixin for centering with different methods
// ======================================================================

@mixin center($context: default) {

  @if $context == horizontal {

    @include relative(l 50%);
    transform: translateX(-50%);

  } @else if $context == vertical {

    @include relative(t 50%);
    transform: translateY(-50%);

  } @else if $context == both {

    @include absolute(t 50%, l 50%);
    transform: translate(-50%, -50%);

  } @else if $context == cover {

    @include absolute(t 0, r 0, b 0, l 0);

  } @else { // assuming default case - oldschool horizontal centering
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
