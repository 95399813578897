// general helper functions
// ======================================================================

// returns true if it is just a number without unit
@function is-number($value) {
  @return type-of($value) == 'number' and unitless($value);
}

// if a unitless number is given, this function returns $base-unit * value
// (except for 0, 0 will be returned instantly)
// if anything else is given, it will return its unqoted value;
@function parse-unit($value) {
  @if $value == 0 {
    @return 0;
  }

  @if is-number($value) {
    @return $base-unit * $value;
  }

  @return $value;
}


// returns the full value for any of the position/side short-forms
@function position-map($short) {
  $short-map: (
    t: top,
    r: right,
    b: bottom,
    l: left
  );

  @if map-has-key($short-map, $short) {
    @return map-get($short-map, $short);
  } @else {
    @warn 'Short key #{$short} not avaiable.';

    @return false;
  }
}
