// svg icons and custom icon styles
// ======================================================================

.icon {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;

  &--kfiapps {
    @include spacing(b 1, r 1);
    display: inline-block;

    @include mediaquery(l) {
      @include spacing(r 0);
    }

    img {
      width: 155px;
      height: 45px;

      @include mediaquery(l) {
        width: 260px;
        height: 75px;
      }
    }
  }

  &--social_github,
  &--social_mail,
  &--social_twitter,
  &--social_website {
    width: 60px;
    height: 60px;
    fill: $color-inverted;
    background: $color-main;
    border-radius: 30px;

    @include mediaquery(l) {
      width: 80px;
      height: 80px;
      border-radius: 40px;
    }

    @include attention() {
      background: $color-inverted;
      fill: $color-default;
    }
  }
}
