// color variables used throughout the project
// ======================================================================

// non-semantic color-names to make your life easier. assign
// them, but only use them to reassign them below. NEVER use
// them directly in other scss-files
$color-white: #fff;
$color-green: #8fc11e;
$color-dark: #21272d;
$color-red: #f0391f;
$color-grey: lighten($color-dark, 50%);
// etc.


// colors you use in your project and other scss-files
$color-default: $color-dark;
$color-main: $color-green;
$color-inverted: $color-white;
$color-background: $color-white;
$color-border: $color-dark;
$color-second: $color-grey;
$color-error: $color-red;
