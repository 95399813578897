// base styles for form elements, fieldsets, labels, inputs etc.
// ======================================================================

// placeholder color, if browser supports it
::placeholder {
  color: lighten($color-default, 50%);
}

// dont allow unselectable elements to be selected
[unselectable] {
  user-select: none;
}

// default styles for text-input forms fields
[type=text],
[type=tel],
[type=email],
[type=search],
[type=number],
[type=password],
select,
textarea {
  @include relative(t 0, l 0);
  @include spacing(b .4);
  width: 100%;
  border-bottom: 1px solid $color-border;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  appearance: none; // no rounded inputs etc.
  color: $color-default;
  outline: 0;
  -webkit-appearance: none;
  z-index: 10;
  background: none;

  @include attention {
    border-color: $color-main;
  }
}

// the label should always be on top of the input
label {
  @include absolute(t 0, b 0, l 0);
  display: block;
  cursor: pointer;
  transition: background 0.2s, color 0.2s, top 0.2s, bottom 0.2s, right 0.2s, left 0.2s;
  color: $color-second;
  width: 100%;

  .focus &,
  .filled & {
    font-size: 70%;
    z-index: 2;
    top: - $base-unit;
  }

  .filled & {
    color: $color-default;
    opacity: .8;
  }

  .focus & {
    color: $color-main;
  }
}

// no border by default for the fieldset
fieldset {
  @include spacing(a 0);
  border: 0;
}

// resize textarea
textarea {
  display: block;
  resize: vertical;
  height: 28px;
  transition: height 0.2s;

  .filled &,
  .focus & {
    height: 190px;
  }
}

select {
  .focus &,
  .filled & {
    color: $color-default;
  }
}

.form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

// form item
.form-item {
  @include relative;
  width: 100%;

  &:not(.form-item__feedback_set) {
    @include spacing(t 1.5);
  }

  form > &:not(.form-item__submit-feedback):not(.form-item__feedback_website) {
    @include mediaquery(l) {
      width: 48%;
    }
  }

  &__feedback_website {
    input,
    label {
      display: none;
    }

    .field--error--message {
      @include relative;
      font-size: 100%;
    }
  }

  &__submit-feedback {
    width: 100%;
    text-align: center;
  }

  &__feedback_subject {
    &:after {
      @include absolute(t -.2, r .2);
      content: '^';
      color: $color-main;
      transform: rotate(180deg);
    }
  }
}

// error message
.field--error--message {
  @include absolute(t 1.2);
  color: $color-error;
  font-size: 70%;

  .focus:not(.form-item__feedback_website) &,
  .filled:not(.form-item__feedback_website) & {
    display: none;
  }
}
