// mixins and other helpers
// ======================================================================

// a small helper for easier mediaqueries - just define the
// breakpoints in variables.scss and you are ready to go
// by default we use the mobile-first idea, no mq == mobile
// you can set the second parameter to true an provide a custom mediaquery

@mixin mediaquery($context, $custom-query: false) {

  @if $context == retina {
    @media print, (min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
      @content;
    }

  // print styles
  } @else if $context == print {
    @media print {
      @content;
    }

  // landscape and portrait mediaqueries
  } @else if $context == landscape {
    @media all and (orientation: landscape) {
      @content;
    }

  } @else if $context == portrait {
    @media all and (orientation: portrait) {
      @content;
    }

  } @else if $context == slim {
    @media all and (orientation: landscape) and (max-height: $breakpoint-slim) {
      @content;
    }

  } @else if $context == high {
    @media all and (orientation: portrait) and (max-height: $breakpoint-high) {
      @content;
    }

  } @else if $context == smax {
    @media all and (max-width: #{map-get($breakpoints, $context)}) {
      @content;
    }

  // custom mediaqueries
  } @else if $custom-query {

    @if map-has-key($breakpoints, $context) {
      @media screen and #{map-get($breakpoints, $context)} {
        @content;
      }
    } @else {
      @warn 'There is no value #{$context} in the list of breakpoints';
    }

  // default: mobile-first mediaqueries as defined in breakpoints
  } @else {

    @if map-has-key($breakpoints, $context) {
      @media screen and (min-width: #{map-get($breakpoints, $context)}) {
        @content;
      }
    } @else {
      @warn 'There is no value #{$context} in the list of breakpoints';
    }
  }

}
