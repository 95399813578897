// helpers for dimensions and positions
// ======================================================================

@mixin max($context) {
  @if $context == width {
    max-width: 100%;
    max-height: none;
    width: auto;
    height: auto;

  } @else if $context == height {
    max-width: none;
    max-height: 100%;
    width: auto;
    height: auto;

  } @else if $context == viewport {
    max-width: 100vw;
    max-height: 100vh;
    width: auto;
    height: auto;

  } @else if $context == full {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;

  } @else {
    @warn 'There is no method #{$context} for maximum dimesions';
  }

}
