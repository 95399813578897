// basic element styles for video / audio etc.
// ======================================================================

audio,
canvas,
img,
video {
  @include max(width);
}

audio,
canvas,
iframe,
img,
picture,
svg,
video {
  vertical-align: middle;
}
