// postbox
// ======================================================================

$path-orig: 'M104 80.7l42.7-.7 37.3-.7.6 31.6-22.4.3-.6-30.2';
$path-hover1: 'M104 79.7l38.6 18 34 15.8-13.5 28.7-20.2-9.5 12.7-27.3';
$path-hover2: 'M104 80.4l38.7-18 34-15.8L190 75.2l-20.3 9.5L157 57.3';
$path-hover3: 'M104 81.3l27.4-32.7 24-28.6 24.2 20.3-14.3 17L142.3 38';
$path-hover4: 'M104 81.4l11-41.2 9.7-36 30.6 8-5.8 21.6-29-7.8';
$path-success: 'M104 80.5l.8-42.6.6-37.5 31.5.6-.4 22.4-30-.5';

.feedback__status {
  @include spacing-inner(t 3, b 5);
  text-align: center;

  svg {
    stroke: $color-default;
    fill: $color-inverted;

    @include attention {
      .feedback__status--animate {
        animation: pulse .7s linear .1s normal infinite forwards;
      }
    }
  }

  &--rack {
    stroke: $color-second;
  }

  &--action {
    stroke: $color-second;
  }

  &--error {
    .feedback__status--action {
      stroke: $color-error;
      fill: $color-error;
    }

    .feedback__status--animate {
      animation: error .7s linear .1s alternate 1 forwards;
    }
  }

  &--success {
    .feedback__status--action {
      stroke: $color-main;
      fill: $color-main;
    }

    .feedback__status--animate {
      animation: success .7s linear .1s alternate 1 forwards;
    }
  }
}

@keyframes pulse {
  0%,
  50%,
  100% {
    d: path($path-orig);
  }

  20% {
    d: path($path-hover1);
  }

  80% {
    d: path($path-hover2);
  }
}

@keyframes success {
  0% {
    d: path($path-hover1);
  }

  5% {
    d: path($path-orig);
  }

  25% {
    d: path($path-hover2);
  }

  50% {
    d: path($path-hover3);
  }

  75% {
    d: path($path-hover4);
  }

  100% {
    d: path($path-success);
  }
}

@keyframes error {
  0% {
    d: path($path-orig);
  }

  100% {
    d: path($path-hover1);
  }
}
