// base styles for html, body and other global elements
// ======================================================================

html {
  min-height: 100%;
  overflow-y: scroll; // all without overlaying scrollbars
  font-family: $font-family;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $font-line-height;
  color: $color-default;
  background-color: $color-background;
  // text-size-adjust: 100%; // ios 8+
  -webkit-tap-highlight-color: transparent;

  @include mediaquery(l) {
    @include font-size(text);
  }
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  @include mediaquery(print) {
    * {
      display: none;
    }

    &::after {
      content: 'Please, do not waste paper by printing webpages.';
    }
  }

  &.no-pointers-events {
    pointer-events: none;
  }
}

// text selection styles
::selection {
  background: $color-main;
  color: $color-default;
  text-shadow: none;
}

header {
  cite,
  h3,
  span,
  p {
    &::selection {
      background: rgba($color-default, 0.2);
      color: $color-inverted;
    }
  }
}
