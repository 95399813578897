// wrapper styles
// ======================================================================

.wrapper {
  @include relative;

  &__inner {
    @include spacing(l auto, r auto);
    @include spacing-inner(l 2, r 2);
    max-width: map-get($breakpoints, l);

    @include mediaquery(xl) {
      width: map-get($breakpoints, l);
    }
  }

  &--right {
    display: flex;
    justify-content: flex-end;
  }

  &--footer {
    @include spacing-inner(l 1, r 1, t 1.5, b 2);

    @include mediaquery(l) {
      @include spacing-inner(t 3);
    }
  }
}
