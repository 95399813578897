// default vertical rhythm / margin-bottom spacing
// ======================================================================

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
blockquote,
address,
hr,
table,
fieldset,
pre,
%rhythm {
  @include spacing(b .5);
}

figure,
p {
  @include spacing;

  @include mediaquery(l) {
    @include spacing(b 2);
  }
}
