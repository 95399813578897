// default link styling
// ======================================================================

a {
  color: $color-main;
  text-decoration: underline;

  @include attention {
    text-decoration: none;
  }

  // links inside of headings are normal colored, non-underlined
  // but get a special symbol after
  h1 > &,
  h2 > &,
  h3 > &,
  h4 > &,
  h5 > &,
  h6 > & {
    text-decoration: none;

    @include attention() {
      text-decoration: underline;
      color: $color-main;
    }

    &::after {
      font-size: 80%;
      color: $color-main;
      content: '\0020\221e';
    }
  }
}
