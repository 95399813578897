// default headings h1 - h6
// ======================================================================

h1 {
  @include spacing(b 0);
  @include font-size(h2);
  font-weight: normal;
  letter-spacing: .05em;

  @include mediaquery(l) {
    @include font-size(h1);
  }
}

h2 {
  @include spacing(b 1);
  @include font-size(h4);
  letter-spacing: $letter-spacing;
  font-weight: 700;
  color: $color-main;
  text-transform: uppercase;

  @include mediaquery(s) {
    @include font-size(h3);
  }

  h1 + & {
    @include spacing(b 3);
  }
}

h3 {
  @include font-size(h4);
  font-weight: 500;
  letter-spacing: $letter-spacing;

  @include mediaquery(l) {
    @include font-size(h3);
  }
}

h4 {
  @include font-size(h4);
}

h5 {
  @include font-size(h5);
}

h6 {
  @include font-size(h6);
}
