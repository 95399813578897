// basic lists styling and reset
// ======================================================================

nav,
fieldset {
  ul,
  ol {
    list-style: none;
  }
}

.content ul {
  @include spacing-inner(l 1);
  list-style: square;
}
