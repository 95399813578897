// breakpoints used throughout the project - use with mixin
// ======================================================================
$breakpoint-slim: 610px;
$breakpoint-high: 900px;

$breakpoints: (
  s: 480px,
  m: 720px,
  l: 960px,
  xl: 1100px,
  xxl: 1400px,
  xxxl: 2000px, // for those insane displays/imacs
  slim: $breakpoint-slim,
  high: $breakpoint-high,
  smax: 480px
);
