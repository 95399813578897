// a very basic grid system
// ======================================================================

// use like this:
// <div class="grid  grid--2">
//  <div class="grid__column"> Your content </div>
//  <div class="grid__column"> Your content </div>
// </div>

// you can throw grids into each other to create custom layouts

.grid {
  @extend %clearfix;

  // by default, the grid starts from breakpoint large and above
  @include mediaquery(l) {
    @include spacing(b 1, l -2);

    .footer__inner & {
      @include spacing(b 0);
    }
  }

  @include mediaquery(xxl) {
    @include spacing(b 2, l -3);

    .footer__inner & {
      @include spacing(b 0);
    }
  }
}

.grid__column {
  @include mediaquery(l) {
    @include spacing-inner(l 2);
    float: left;
    min-height: 1px;
  }

  @include mediaquery(xxl) {
    @include spacing-inner(l 3);
  }
}

// now define the different grid column widths for some
// ======================================================================

// two columns
.grid--2 > .grid__column {
  @include mediaquery(l) {
    width: 50%;
  }
}

// three colums
.grid--3 > .grid__column {
  @include mediaquery(l) {
    width: 33.3333%;
  }
}

// four colums
.grid--4 > .grid__column {
  @include mediaquery(l) {
    width: 25%;
  }
}

// five colums
.grid--5 > .grid__column {
  @include mediaquery(l) {
    width: 20%;
  }
}

// six colums
.grid--6 > .grid__column {
  @include mediaquery(l) {
    width: 16.6666%;
  }
}
