// zindex configuration - use with mixin
// ======================================================================

$z-indexes: (
  sky: 5000,
  hair: 4000,
  head: 3000,
  belly: 2000,
  knees: 1000,
  feet: 1,
  ground: -1
);
