// base unit definition and default font styles and family
// ======================================================================

// base font settings and font-size
$font-size: 16px; // default font-size -- will be used as 1rem
$font-weight: normal;
$font-line-height: 1.5;
$font-family: 'Fira Sans', Arial, sans-serif;
$font-family-headings: 'Fira Sans', Arial, sans-serif;
$letter-spacing: .05em;

// base unit - default font-size * line-height in rem and half/double
$base-unit-in-px: $font-size * $font-line-height;
$base-unit: 1rem * $font-line-height;
$base-half: $base-unit / 2;
$base-third: $base-unit / 3;
$base-quart: $base-unit / 4;
$base-double: $base-unit * 2;
$base-triple: $base-unit * 3;
$base-quadruple: $base-unit * 4;
$base-vw-l: .45;

// globally used widths (max-container sizes e.g.)
$width-sth: 1000px;
$width-nav: 240px;
$width-logo: 132px;
$width-logo-large: 195px;

// globally used heights (header-height e.g.)
$height-sth: 1000px;
$height-header: 50px;
$height-header-large: 70px;
